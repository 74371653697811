import React from 'react';
import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';
import TextBlock from 'common/components/TextBlock';
import imageDecorationSmall from '../assets/image_decoration_small.png';
import { useBackendStore } from 'services/backendService';

const PersonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  max-height: 300px;
  position: relative;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &::after {
    content: url(${imageDecorationSmall});
    position: absolute;
    bottom: 0;
    right: 0;
    width: 41.7px;
    height: 20.81px;
  }

  img {
    max-width: calc(100% - 64px);
    max-height: 300px;
    width: auto;
    height: auto;
    display: block;
    ${mediaMaxPx(
      900,
      css`
        max-height: auto;
        max-width: 100%;
      `,
    )}
    ${mediaMaxPx(
      700,
      css`
        max-height: 300px;
        max-width: 100%;
      `,
    )}
  }

  ${mediaMaxPx(
    900,
    css`
      justify-content: flex-start;
    `,
  )}
  ${mediaMaxPx(
    700,
    css`
      height: fit-content;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 36px;
    `,
  )}
`;

export default function Person({ image, name, copy }) {
  const env = useBackendStore(state => state.env);

  return (
    <PersonContainer>
      <ImageContainer>
        <img src={env + image.url} alt={image.alternativeText} />
      </ImageContainer>
      <TextBlock headline={name} copy={copy} />
    </PersonContainer>
  );
}

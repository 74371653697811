import React from 'react';
import { Helmet } from 'react-helmet';

export default function Seo({ seoData, page }) {
  const domain = 'https://www.nicetomeetyou.digital/';

  if (!seoData) return <div></div>;
  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <meta
        name="robots"
        content={seoData.preventIndexing === true ? 'noindex, follow' : 'index, follow'}
      />
      {seoData.canonicalLink ? (
        <link rel="canonical" href={seoData.canonicalLink} />
      ) : (
        <link rel="canonical" href={domain + page} />
      )}
    </Helmet>
  );
}

import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { H2 } from 'styles/text';
import { mediaMaxPx } from 'styles';

export const SelectionBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

export const CategoryContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  ${mediaMaxPx(
    700,
    css`
      margin-top: 32px;
    `,
  )}
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  ${mediaMaxPx(
    700,
    css`
      margin-bottom: 16px;
    `,
  )}
`;

export const IconWrapper = styled.div`
  width: 40px;
  padding: 8px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledHeadline = styled(H2)`
  margin: 0;
  color: ${colors.text.veryLight};
  ${mediaMaxPx(
    700,
    css`
      text-align: center;
      font-size: 36px;
      line-height: 56px;
    `,
  )}
`;

export const CategoryHeadline = styled(H2)`
  margin: 0 16px;
  position: relative;
  line-height: 48px;
  cursor: pointer;
  color: ${props => (props.active ? colors.text.veryLight : colors.text.disabled)};
  ${props =>
    !props.active &&
    css`
      &::after {
        white-space: nowrap;
        content: 'coming soon';
        font-weight: normal;
        font-size: 16px;
        margin-left: 16px;
      }
    `}

  ${mediaMaxPx(
    700,
    css`
      font-size: 24px;
      line-height: 28px;
    `,
  )}
`;

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useBackendStore } from 'services/backendService';
import { EntryContainer, LinkContainer, NavigationContainer, Link } from './styles';
import { H3 } from 'styles/text';
import ArrowLeft from 'assets/icons/arrow_left.svg';
import ArrowRight from 'assets/icons/arrow_right.svg';

export default function BlogEntry() {
  const { id } = useParams();
  const history = useHistory();
  const blogEntries = useBackendStore(state => state.blogEntries);
  if (!blogEntries) return <div></div>;
  const currentIndex = blogEntries.findIndex(entry => entry.id == id);
  const nextEntry = blogEntries[currentIndex + 1] || blogEntries[0];
  const previousEntry = blogEntries[currentIndex - 1] || blogEntries[blogEntries.length - 1];

  const clickHandler = (id, headline) => {
    history.push('/blog/' + id + '/' + headline);
  };

  return (
    <NavigationContainer>
      <EntryContainer>
        <LinkContainer>
          <Link left onClick={() => clickHandler(nextEntry.id, nextEntry.headline)}>
            <ArrowLeft />
            <H3>{nextEntry.headline}</H3>
          </Link>
          <Link onClick={() => clickHandler(previousEntry.id, previousEntry.headline)}>
            <H3>{previousEntry.headline}</H3>
            <ArrowRight />
          </Link>
        </LinkContainer>
      </EntryContainer>
    </NavigationContainer>
  );
}

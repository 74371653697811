import React from 'react';
export default function Config() {
  const setEnvironment = env => {
    localStorage.setItem('ENV', env);
  };
  const isActive = env => {
    return localStorage.getItem('ENV') === env;
  };
  return (
    <div>
      <button onClick={() => setEnvironment('local')}>Local{isActive('local') && '*'}</button>
      <button onClick={() => setEnvironment('develop')}>Develop{isActive('develop') && '*'}</button>
      <button onClick={() => setEnvironment('deploy')}>Deploy{isActive('deploy') && '*'}</button>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import {
  SlideshowContainer,
  SlidesContainer,
  Slide,
  ButtonContainer,
  SliderButton,
  SlideTextContainer,
  SlideHeadline,
  SlideSubline,
} from './styles';
import BulletNavigation from 'common/components/BulletNavigation';
import { useBackendStore } from 'services/backendService';
import { slideshowApi } from 'services/slideshowService';

export default function Slideshow({ slides, children }) {
  const content = slides ? slides : children[0];
  const [slideNumber, setSlideNumber] = useState(0);
  const [translate, setTranslate] = useState(0);
  useEffect(() => {
    slideshowApi.getState().setActiveSlide(0);
  }, []);

  const moveSlides = direction => {
    const newSlideNumber = slideNumber + direction * -1;
    if (newSlideNumber >= 0 && newSlideNumber <= content.length - 1) {
      slideshowApi.getState().setActiveSlide(newSlideNumber);
      setSlideNumber(newSlideNumber);
      const translateDistance = translate + direction * 100;
      setTranslate(translateDistance);
    }
  };

  const moveToBullet = index => {
    const newSlideNumber = index;
    setSlideNumber(newSlideNumber);
    const translateDistance = index * -100;
    setTranslate(translateDistance);
  };

  const env = useBackendStore(state => state.env);

  return (
    <SlideshowContainer>
      <SlidesContainer translate={translate}>
        {children && children}
        {slides &&
          slides.map((slide, index) => (
            <Slide
              key={index}
              bgColor={slide.backgroundColor}
              fontColor={slide.fontColor}
              image={slide.image && env + slide.image.url}
            >
              <SlideTextContainer>
                <SlideHeadline>&ldquo;{slide.headline}&ldquo;</SlideHeadline>
                <SlideSubline>({slide.subline})</SlideSubline>
              </SlideTextContainer>
            </Slide>
          ))}
      </SlidesContainer>
      <ButtonContainer>
        {slideNumber > 0 && <SliderButton onClick={() => moveSlides(1)} />}
        {slideNumber < content.length - 1 && <SliderButton right onClick={() => moveSlides(-1)} />}
      </ButtonContainer>
      <BulletNavigation
        slides={content}
        moveRight={() => moveSlides(1)}
        moveLeft={() => moveSlides(-1)}
        moveTo={moveToBullet}
        activeSlide={slideNumber}
        absolute
      />
    </SlideshowContainer>
  );
}

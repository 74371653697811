import React from 'react';
import Seo from 'common/components/Seo';
import VideoScreen from 'common/components/VideoScreen';
import { ScalingPaddington } from 'common/components/Paddington';
import { SingleColumnGrid } from 'common/components/Grid';
import TextBlock from 'common/components/TextBlock';
import { useBackendStore } from 'services/backendService';

export default function Was() {
  const data = useBackendStore(state => state.was);
  if (!data) return <div>loading...</div>;
  const { headerVideo, textBlock, seo } = data;
  const { video, thumbnail } = headerVideo;

  return (
    <>
      <Seo seoData={seo} page={'was'} />
      <VideoScreen video={video.url} poster={thumbnail.url} />
      <ScalingPaddington>
        <SingleColumnGrid>
          {textBlock.map((p, index) => (
            <TextBlock key={index} offsetText={index} headline={p.headline} copy={p.copy} />
          ))}
        </SingleColumnGrid>
      </ScalingPaddington>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { menuApi } from 'services/menuService';
import { useHistory } from 'react-router-dom';
import { useMenuStore } from 'services/menuService';
import Burger from './Burger';
import LogoIcon from './assets/logo.svg';
import { useColorStore } from 'services/colorService';
import { mediaMaxPx } from 'styles';

const NavContainer = styled.div`
  width: 100%;
  height: 40px;
  position: fixed;
  top: 40px;
  left: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
  ${mediaMaxPx(
    700,
    css`
      height: 70px;
      align-items: center;
      padding: 20px 40px;
      top: 0;
      background-color: ${props =>
        props.navBgcolor ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'};
      transition: 0.2s ease-in-out;
    `,
  )}
`;

const Logo = styled.div`
  width: 56px;
  height: fit-content;
  cursor: pointer;
  svg {
    path:first-child {
      transition: 0.2s ease-in;
      fill: ${props => props.navColor};
    }
  }
`;

export default function Nav() {
  const isVisible = useMenuStore(state => state.navVisible);
  const navColor = useColorStore(state => state.navColor);
  const history = useHistory();
  const isOpen = useMenuStore(state => state.isOpen);
  const [needsBackground, setNeedsBackground] = useState(false);
  const toggleMenu = () => menuApi.getState().toggleIsOpen();
  const moveHome = () => {
    history.push('/');
    isOpen && toggleMenu();
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > window.innerHeight ? setNeedsBackground(true) : setNeedsBackground(false);
    });
    return () => {
      window.removeEventListener('scroll', () => {
        window.scrollY > window.innerHeight ? setNeedsBackground(true) : setNeedsBackground(false);
      });
    };
  }, []);

  return (
    isVisible && (
      <NavContainer navBgcolor={needsBackground && !isOpen}>
        <Burger clickHandler={toggleMenu} navColor={navColor} isOpen={isOpen} />
        <Logo onClick={moveHome} navColor={navColor}>
          <LogoIcon />
        </Logo>
      </NavContainer>
    )
  );
}

import { createGlobalStyle, css } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { fontFamily, textStyle } from './styles/text';
import { colors } from 'styles/colors';

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html, body{
    transition: background-color 0.5s ease-in-out, color 0.2s ease-in-out;
    padding: 0;
    margin: 0;
    ${fontFamily};
    ${textStyle};
    width: 100vw;
  }

  body{
    position: relative;
  }

  div {    
    box-sizing: border-box;
  }
  a {
    appearance: none;
    outline: none; 
    color: ${colors.menu};
  } 

  a:hover {      
    text-decoration: underline;
  }

  blockquote {
    border-left: 2px solid ${colors.primary};
    padding-left: 8px;
    font-style: italic;
    margin: 8px 16px;
  }
`;

export const mediaMaxPx = (bp, style) => {
  return css`
    @media only screen and (max-width: ${bp}px) {
      ${style}
    }
  `;
};

export const mediaMaxLandscape = (bp, style) => {
  return css`
    @media only screen and (orientation: landscape) {
      ${mediaMaxPx(
        bp,
        css`
          ${style}
        `,
      )}
    }
  `;
};

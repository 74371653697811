import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { mediaMaxPx } from 'styles';

export const NavigationContainer = styled.div`
  margin-top: 128px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const EntryContainer = styled.div``;

export const TeaserImage = styled.img`
  max-width: 120px;
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${mediaMaxPx(
    700,
    css`
      flex-direction: column;
    `,
  )}
`;

export const Link = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primary};
  margin: 0 16px;
  cursor: pointer;
  position: relative;

  h3 {
    margin: 4px 0;
  }
  svg {
    transition: 0.2s ease-in-out;
    margin: 0 16px;
  }

  &:hover {
    svg {
      transform: translateX(${props => (props.left ? -10 : 10)}px);
    }
  }

  ${mediaMaxPx(
    700,
    css`
      flex-direction: column;
      margin: 8px 0;
      text-align: center;
      h3 {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
      }
      svg {
        transform: rotate(90deg);
        margin: 16px;
      }
    `,
  )}
`;

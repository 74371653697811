import React from 'react';
import { FooterContainer, TextContainer, SocialContainer, SocialIcon } from './styles';
import ReactMarkdown from 'react-markdown';
import Loader from 'components/Loader';
import { useBackendStore } from 'services/backendService';

export default function Footer() {
  const env = useBackendStore(state => state.env);
  const data = useBackendStore(state => state.footer);
  if (!data) return <Loader />;
  const { footerBlock, socialMedia } = data;
  const { socialMediaLink } = socialMedia;

  const externLinkHandler = link => {
    window.location.assign(link);
  };

  return (
    <FooterContainer>
      <SocialContainer>
        {socialMediaLink.map((link, index) => (
          <SocialIcon
            key={'socialFooter' + index}
            src={env + link.icon.url}
            onClick={() => externLinkHandler(link.link)}
          />
        ))}
      </SocialContainer>
      {footerBlock.map((item, index) => (
        <TextContainer key={'footer' + index}>
          <ReactMarkdown>{item.list}</ReactMarkdown>
        </TextContainer>
      ))}
    </FooterContainer>
  );
}

import React from 'react';
import { useBackendStore } from 'services/backendService';
import Loader from 'components/Loader';
import { ScalingPaddington } from 'common/components/Paddington';
import { AngebotSlide, ImageContainer, TextContainer } from './styles';
import Slideshow from 'common/components/Slideshow';
import { H2, H3 } from 'styles/text';
import { Grid, SingleColumnGrid } from 'common/components/Grid';
import TextBlock from 'common/components/TextBlock';
import { useSlideshowStore } from 'services/slideshowService';
import Seo from 'common/components/Seo';

const renderTextBlock = activeSlide => {
  const { textBlockHeadline, textBlock } = activeSlide;
  return <TextBlock copy={textBlock} headline={textBlockHeadline} />;
};

export default function Angebot() {
  const env = useBackendStore(state => state.env);
  const data = useBackendStore(state => state.angebot);
  const activeSlideId = useSlideshowStore(state => state.activeSlide);
  if (!data) return <Loader />;
  const { slides, seo } = data;
  const activeSlide = slides[activeSlideId];

  return (
    <>
      <Seo seoData={seo} page={'angebot'} />
      <Slideshow>
        {[
          slides &&
            slides.map((s, index) => (
              <AngebotSlide key={index} bgColor={s.backgroundColor}>
                <Grid>
                  <ImageContainer>
                    {s.images.map((image, index) => (
                      <img key={'img' + index} src={env + image.url} alt={image.alternativeText} />
                    ))}
                  </ImageContainer>
                  <TextContainer headlineColor={s.headlineColor} sublineColor={s.sublineColor}>
                    <H2>{s.headline}</H2>
                    <H3>{s.subline}</H3>
                  </TextContainer>
                </Grid>
              </AngebotSlide>
            )),
        ]}
      </Slideshow>
      <ScalingPaddington>
        <SingleColumnGrid>{activeSlide.textBlock && renderTextBlock(activeSlide)}</SingleColumnGrid>
      </ScalingPaddington>
    </>
  );
}

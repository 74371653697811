import React from 'react';
import ReactDOM from 'react-dom';
import Routes from 'components/Routes';
import Start from './components/Start';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from './styles';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
      <Helmet>
        <title data-react-helmet="true">Nicetomeetyou macht Treffen zu Volltreffern.</title>
        <meta
          name="description"
          content="Vorträge – Coaching - Workshop - Beratung – Event. Wir geben guter Kommunikation Raum. Weil schlechte Treffen gutes Geld kosten."
          data-react-helmet="true"
        />
      </Helmet>
      <GlobalStyle />
      <BrowserRouter>
        <Start />
        <Routes />
        <Footer />
      </BrowserRouter>
    </>
  );
};
const root = document.createElement('div');
document.body.appendChild(root);

ReactDOM.render(<App />, root);

export default App;

import React from 'react';
import { ScalingPaddington } from 'common/components/Paddington';
import { TextContainer } from './styles';
import { SingleColumnGrid } from 'common/components/Grid';
import TextBlock from 'common/components/TextBlock';
import { useParams } from 'react-router-dom';
import { useBackendStore } from 'services/backendService';
import Loader from 'components/Loader';
import HeaderContent from './HeaderContent';
import BlogNavigation from './BlogNavigation';
import Seo from 'common/components/Seo';

export default function BlogEntry() {
  const { id } = useParams();

  const blogEntries = useBackendStore(state => state.blogEntries);
  if (!blogEntries) return <Loader />;
  const currentEntry = blogEntries.find(entry => entry.id == id);
  if (!currentEntry) return <Loader />;
  const { headerContent, headline, textBlock, thumbnail, seo } = currentEntry;

  return (
    <>
      <Seo seoData={seo} />
      <ScalingPaddington>
        <HeaderContent
          content={headerContent}
          type={headerContent && headerContent.mime}
          headline={headline}
          poster={thumbnail && thumbnail.formats.large.url}
        />
        <TextContainer>
          <SingleColumnGrid>
            {textBlock.map((text, index) => (
              <TextBlock key={index} small headline={text.headline} copy={text.copy} />
            ))}
          </SingleColumnGrid>
        </TextContainer>
        <BlogNavigation />
      </ScalingPaddington>
    </>
  );
}

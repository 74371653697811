import React, { useEffect } from 'react';
import Seo from 'common/components/Seo';
import { ScalingPaddington } from 'common/components/Paddington';
import TextBlock from 'common/components/TextBlock';
import {
  Container,
  TeaserContainer,
  TeaserCopy,
  TeaserHeadline,
  TeaserTextContainer,
} from './styles';
import { useBackendStore } from 'services/backendService';
import { backendApi } from 'services/backendService';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader';

export default function Blog() {
  const env = useBackendStore(state => state.env);
  const history = useHistory();
  useEffect(() => {
    backendApi.getState().getBlogEntries();
  }, []);
  const blogEntries = useBackendStore(state => state.blogEntries);
  const sendungen = useBackendStore(state => state.sendung);

  if (!blogEntries || !sendungen) return <Loader />;
  const blogIntro = sendungen.category.find(s => s.name === 'Blog');
  const { headline, introText, seo } = blogIntro;

  const clickHandler = (id, headline) => {
    history.push('/blog/' + id + '/' + headline);
  };

  return (
    <>
      <Seo seoData={seo} page={'blog'} />
      <ScalingPaddington>
        <Container>
          <TextBlock headline={headline} copy={introText} maxWidth={500} />
          {blogEntries.map((entry, index) => (
            <TeaserContainer key={index} onClick={() => clickHandler(entry.id, entry.headline)}>
              {entry.thumbnail && (
                <img
                  src={env + entry.thumbnail.formats.small.url}
                  alt={entry.thumbnail.alternativeText}
                />
              )}
              <TeaserTextContainer>
                <TeaserHeadline>{entry.headline}</TeaserHeadline>
                <TeaserCopy>{entry.teaserCopy}</TeaserCopy>
              </TeaserTextContainer>
            </TeaserContainer>
          ))}
        </Container>
      </ScalingPaddington>
    </>
  );
}

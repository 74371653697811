import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';
import { colors } from 'styles/colors';

export const AngebotSlide = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 100vh;
  background-color: ${props => (props.bgColor ? props.bgColor : '#efefef')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 80px;

  div {
    height: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  ${mediaMaxPx(
    700,
    css`
      padding: 80px 40px;
    `,
  )}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 2 * 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  img {
    max-width: 100%;
    max-height: 50%;
    width: auto;
    height: auto;
    margin: 16px 0;
  }

  ${mediaMaxPx(
    850,
    css`
      display: none;
    `,
  )}
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h2 {
    color: ${props => (props.headlineColor ? props.headlineColor : colors.text.accent)};
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 0;
    &::after {
      content: '';
      height: 6px;
      width: 46px;
      border-radius: 10px;
      position: absolute;
      background-color: ${colors.underscore};
      bottom: 0;
      left: 0;
    }
  }
  h3 {
    color: ${props => (props.sublineColor ? props.sublineColor : colors.text.veryLight)};
  }

  ${mediaMaxPx(
    850,
    css`
      h2 {
        font-size: 24px;
        line-height: 36px;
      }
    `,
  )}
`;

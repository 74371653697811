import React from 'react';
import Seo from 'common/components/Seo';
import {
  SelectionBackground,
  CategoryContainer,
  Category,
  StyledHeadline,
  CategoryHeadline,
  IconWrapper,
} from './styles';
import { useHistory } from 'react-router-dom';
import { useBackendStore } from 'services/backendService';
import Loader from '../Loader';

export default function Sendung() {
  const history = useHistory();

  const clickHandler = path => {
    history.push(path);
  };

  const env = useBackendStore(state => state.env);
  const data = useBackendStore(state => state.sendung);
  if (!data) return <Loader />;
  const { headline, category, seo } = data;

  return (
    <>
      <Seo seoData={seo} page={'textTonBild'} />
      <SelectionBackground>
        <StyledHeadline>{headline}</StyledHeadline>
        <CategoryContainer>
          {category.map((c, index) => (
            <Category key={index}>
              <IconWrapper>
                <img src={env + c.icon.url} alt={c.icon.alternativeText} />
              </IconWrapper>
              <CategoryHeadline active={c.isLive} onClick={() => c.isLive && clickHandler(c.link)}>
                {c.name}
              </CategoryHeadline>
            </Category>
          ))}
        </CategoryContainer>
      </SelectionBackground>
    </>
  );
}

import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useMenuStore } from 'services/menuService';
import { colors } from 'styles/colors';
import { H1 } from 'styles/text';
import { Link, useHistory } from 'react-router-dom';
import { menuApi } from 'services/menuService';
import { colorApi } from 'services/colorService';
import { mediaMaxPx, mediaMaxLandscape } from 'styles';
import { useBackendStore } from 'services/backendService';
import Loader from '../Loader';

const moveDown = keyframes`
    from{top: -100vh}
    to{top:0}
`;

export const MenuContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.menu};
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  align-items: center;
  position: fixed;
  color: white;
  z-index: 1000;
  padding: 0 40px;
  animation: ${moveDown} 0.5s ease-in-out;
  pointer-events: all;
`;

export const LinkContainer = styled.div`
  padding: 64px 0px 64px 140px;
  ${mediaMaxPx(
    800,
    css`
      padding-left: 0;
      p {
        font-size: 36px;
        line-height: 56px;
      }
    `,
  )};
  ${mediaMaxPx(
    350,
    css`
      p {
        font-size: 32px;
        line-height: 40px;
      }
    `,
  )};
  ${mediaMaxLandscape(
    850,
    css`
      padding-left: 48px;
      p {
        font-size: 24px;
        line-height: 40px;
      }
    `,
  )}
`;

const expandAfterScore = keyframes`
  from{width: 0px}
  to{width: 200px}
`;

const StyledLink = styled(H1)`
  font-size: 64px;
  line-height: 80px;
  position: relative;
  width: auto;
  cursor: pointer;
  text-transform: uppercase;
  color: ${colors.text.veryLight};

  &:hover::after {
    animation: ${expandAfterScore} 0.4s ease-in-out;
    content: '';
    height: 8px;
    width: 200px;
    position: absolute;
    background-color: ${colors.underscore};
    margin-left: 16px;
    bottom: 18px;
    transform-origin: left;
  }
`;

export default function Menu() {
  const history = useHistory();
  const isOpen = useMenuStore(state => state.isOpen);
  const currentY = window.scrollY;
  useEffect(() => {
    colorApi.getState().setNavColor(isOpen ? 'light' : 'dark');
  }, [isOpen]);

  const preventScroll = () => window.scrollTo(0, currentY);

  useEffect(() => {
    isOpen
      ? window.addEventListener('scroll', preventScroll)
      : window.removeEventListener('scroll', preventScroll);
    return () => window.removeEventListener('scroll', preventScroll);
  }, [isOpen]);

  const linkHandler = path => {
    history.push(path);
    menuApi.getState().toggleIsOpen();
  };

  const menuPoints = useBackendStore(state => state.menu);
  if (!menuPoints) return <Loader />;

  return (
    <MenuContainer isOpen={isOpen}>
      <LinkContainer>
        {menuPoints.map((point, index) => (
          <Link key={index} to={point.link + '/'}>
            <StyledLink onClick={() => linkHandler(point.link)}>{point.text}</StyledLink>
          </Link>
        ))}
      </LinkContainer>
    </MenuContainer>
  );
}

import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { mediaMaxPx } from 'styles';
import { H2, H3 } from 'styles/text';
import ReactMarkdown from 'react-markdown';

export const TextContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${props => (props.odd ? 128 : 0)}px;
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props => props.maxWidth}px;
    `}
  ${mediaMaxPx(
    600,
    css`
      padding: 0;
      width: 100%;
      h2 {
        font-size: 32px;
        line-height: 36px;
      }
    `,
  )}
`;

export const HeadlineContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 10px;
`;

export const StyledH2 = styled(H2)`
  color: ${colors.text.accent};
  margin: 0;
`;

export const StyledH3 = styled(H3)`
  color: ${colors.text.accent};
  margin: 0;
`;

export const StyledText = styled.div`
  padding: ${props => (props.small ? 24 : 32)}px 0 32px ${props => (props.small ? 0 : 64)}px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  position: relative;

  p {
    hyphens: auto;
    margin-top: 0;
  }

  p img {
    max-width: 100%;
  }

  &::after {
    ${props => !props.underline && 'display: none'};
    content: '';
    height: 6px;
    width: 46px;
    border-radius: 10px;
    position: absolute;
    background-color: ${colors.underscore};
    top: 0px;
    left: ${props => (props.small ? 0 : 64)}px;
    ${mediaMaxPx(
      900,
      css`
        left: 0;
      `,
    )}
  }

  ${mediaMaxPx(
    900,
    css`
      padding: 32px 0;
    `,
  )}
`;

const isOdd = index => {
  return index % 2;
};

export default function TextBlock({ offsetText = false, headline, copy, maxWidth, small = false }) {
  return (
    <TextContainer odd={offsetText && isOdd(offsetText)} maxWidth={maxWidth}>
      <HeadlineContainer>
        {small ? <StyledH3>{headline}</StyledH3> : <StyledH2>{headline}</StyledH2>}
      </HeadlineContainer>
      <StyledText small={small} underline={headline}>
        <ReactMarkdown source={copy} />
      </StyledText>
    </TextContainer>
  );
}

import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { H2, H3 } from 'styles/text';
import { mediaMaxPx } from 'styles';
import arrowLeft from 'assets/icons/large_arrow_left.png';
import arrowLeft2x from 'assets/icons/large_arrow_left@2x.png';
import arrowRight from 'assets/icons/large_arrow_right.png';
import arrowRight2x from 'assets/icons/large_arrow_right@2x.png';

export const SlideshowContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
`;

export const SliderButton = styled.div`
  width: 100%;
  height: 100%;
  cursor: -webkit-image-set(
        url(${props => (props.right ? arrowRight : arrowLeft)}) 1x,
        url(${props => (props.right ? arrowRight2x : arrowLeft2x)}) 2x
      )
      0 0,
    auto;
`;

export const SlidesContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  transform: translateX(${props => props.translate}vw);
  transition: 1s ease-in-out;
  position: absolute;
`;

export const Slide = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: auto;
  background-image: url(${props => props.image});
  background-color: ${props => (props.bgColor ? props.bgColor : '#efefef')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.fontColor ? props.fontColor : colors.text.veryLight)};
`;

export const SlideTextContainer = styled.div`
  text-align: center;
`;

export const SlideHeadline = styled(H2)`
  ${mediaMaxPx(
    850,
    css`
      font-size: 24px;
      line-height: 26px;
    `,
  )}
`;

export const SlideSubline = styled(H3)`
  font-weight: 400;
  ${mediaMaxPx(
    850,
    css`
      font-size: 20px;
      line-height: 24px;
    `,
  )}
`;

import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';
import { H2 } from 'styles/text';
import { colors } from 'styles/colors';
import iconPlay from 'assets/icons/icon_play.png';
import iconPause from 'assets/icons/icon_pause.png';

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1192px;
  height: calc(100vh - 80px);
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  ${mediaMaxPx(
    800,
    css`
      height: fit-content;
    `,
  )}
  ${mediaMaxPx(
    600,
    css`
      padding: 64px 0;
    `,
  )}
`;

export const VideoUi = styled.div`
  max-height: 80%;
  height: auto;
  width: calc((((100vh - 80px - (2 * 64px)) / 1.25) / 9) * 16);
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: -40px;
  width: 30px;
  svg {
    cursor: pointer;
    height: 20px;
    margin: 8px 0;
  }
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  appearance: none;
`;

export const PlayOverlay = styled.div`
  width: 100%;
  height: 100%;
  cursor: url(${iconPlay}) 4 12, auto;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
`;

export const PauseOverlay = styled(PlayOverlay)`
  width: 100%;
  height: 100%;
  cursor: url(${iconPause}) 4 12, auto;
`;

export const StyledHeadline = styled(H2)`
  color: ${colors.text.accent};
  margin: 16px 0 0 0;
  justify-self: left;
  width: 100%;
  word-break: break-word;
  ${mediaMaxPx(
    800,
    css`
      left: 0;
      font-size: 28px;
      line-height: 38px;
      max-width: 100%;
    `,
  )};
`;

export const HeaderImage = styled.img`
  max-width: 100%;
  max-height: 80%;
  height: auto;
  width: auto;
`;

export const MobilePlayButton = styled.div`
  width: 36px;
  position: absolute;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  display: none;
  ${mediaMaxPx(
    700,
    css`
      display: ${props => (props.isPlaying ? 'none' : 'block')};
    `,
  )}
`;
